import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { performLogin, performLogout } from '../../reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import ustadiumLoginLogo from '../../assets/images/ustadium-login-logo.png';
import toast from 'react-hot-toast';
import { ReactComponent as TopRightIcon } from '../../assets/svg/topRightIcon.svg';
import { ReactComponent as CenterIcon } from '../../assets/svg/centerIcon.svg';
import { ReactComponent as RightCenterIcon } from '../../assets/svg/rightCenterIcon.svg';
import { ReactComponent as BottomRightIcon } from '../../assets/svg/bottomRightIcon.svg';
import { ReactComponent as BottomLeftIcon } from '../../assets/svg/bottomLeftIcon.svg';
import ReactModal from 'react-modal';
import Signup from '../signup';
import { modalStyles } from '../../utils/miscUtils';

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error } = useSelector(state => state.auth);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth <= 600);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const lastViewedPost = localStorage.getItem('lastViewedPost');
      const lastViewedTake = localStorage.getItem('lastViewedTake');

      if (lastViewedPost) {
        navigate(`/p/${lastViewedPost}`);
        localStorage.removeItem('lastViewedPost');
      }
      else if (lastViewedTake) {
        navigate(`/takes/${lastViewedTake}`);
        localStorage.removeItem('lastViewedTake');
      }
      else {
        navigate('/home');
      }
    }

    ReactModal.setAppElement('body');

    const handleResize = () => {
      setIsMediumScreen(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isAuthenticated, isMediumScreen]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(performLogin(credentials));
    setCredentials({username: '', password: ''});
  }

  const notifyLoginFailed = () => {
    dispatch(performLogout());
    return toast.error(error, {
      position: 'top-right'
    });
  }
    
  return (
    isMediumScreen ? (
    <div className="login-page-container">
      <div className="login-logo-container">
        <TopRightIcon className='top-right-svg'/>
        <CenterIcon className='center-svg' />
        <RightCenterIcon className='right-center-svg' />
        <BottomRightIcon className='bottom-right-svg' />
        <BottomLeftIcon className='bottom-left-svg' />
        <div className='logo-circle-container'>
          <img src={ustadiumLoginLogo} alt="ustadium-logo" />
        </div>
        <h2>UStadium is currently undergoing maintenance.</h2>
        <h2>We are looking to improve your fan experience.</h2>
        <h2>Thank you for your patience and understanding.</h2>
        <form className="loginForm" onSubmit={handleSubmit}>
          {/* <input
            type="text"
            name="username"
            placeholder="Username"
            value={credentials.username}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={credentials.password}
            onChange={handleChange}
          />
          <button type="submit">Login</button>
          <button type="button" onClick={openModal}>Sign Up</button> */}
        </form>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Signup Modal"
          className="signup-modal"
          overlayClassName="signup-modal-overlay"
          style={modalStyles}
        >
          <Signup
            onClose={closeModal}
          />
        </ReactModal>
      </div>
      {error && notifyLoginFailed()}
    </div>
  ) : (
    <div className="login-page-container">
      <div className="login-logo-container">
        <TopRightIcon className='top-right-svg'/>
        <CenterIcon className='center-svg' />
        <RightCenterIcon className='right-center-svg' />
        <BottomRightIcon className='bottom-right-svg' />
        <BottomLeftIcon className='bottom-left-svg' />
        <div className='logo-circle-container'>
          <img src={ustadiumLoginLogo} alt="ustadium-logo"></img>
        </div>
      </div>
      <div className="login-form-container">
        <form className="loginForm" onSubmit={handleSubmit}>
          <h2>UStadium is currently undergoing maintenance.</h2>
          <h2>We are looking to improve your fan experience.</h2>
          <h2>Thank you for your patience and understanding.</h2>
          {/* <input
            type="text"
            name="username"
            placeholder="Username"
            value={credentials.username}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={credentials.password}
            onChange={handleChange}
          />
          <button type="submit">Login</button>
          <button type="button" onClick={openModal}>Sign Up</button> */}
        </form>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Signup Modal"
        className="signup-modal"
        overlayClassName="signup-modal-overlay"
        style={modalStyles}
      >
        <Signup
          onClose={closeModal}
        />
      </ReactModal>
      {error && notifyLoginFailed()}
    </div>
  ));
};

export default Login;
